<div class="con">
  <h2> Impressum </h2>
  <p>
  Angaben gem. § 5 TMG:<br>
  Mikhail, Andreev<br>
  Am Sportplatz 25<br>
  40670 Meerbusch<br>
  <br>
  Telefon: +49 (0) 1523 4399315<br>
  E-Mail: mikhail@andreev.work<br>
  <br>
  Für die Richtigkeit, Vollständigkeit und Aktualität der Inhalte auf dieser Webseite wird keine Gewähr übernommen.<br>
  <br>
  Die auf dieser Webseite veröffentlichen Inhalte und Werke unterliegen dem <a href="http://www.gesetze-im-internet.de/bundesrecht/urhg/gesamt.pdf">deutschen Urheberrecht</a>.<br>
  Die Inhalte Dritter werden als solche kenntlich gemacht.
  </p>

  <h2> Datenschutz </h2>
  <h3>Auskunft, Löschung, Sperrung</h3>
  <p>
  Zu jedem Zeitpunkt können Sie sich über die personenbezogenen Daten, deren Herkunft und Empfänger und den Nutzen der Datenverarbeitung informieren und unentgeltlich eine Korrektur, Sperrung oder Löschung dieser Daten verlangen. Bitte nutzen Sie dafür die im Impressum angegebenen Kontaktwege. 
  </p>

  <h3>Server-Log-Files</h3>
  <p>Der Seiten-Provider erhebt und speichert automatisch Daten in Server-Log Files, die von Ihrem Browser an uns übermittelt werden. Diese Daten enthalten:</p><p>– Browsertyp/ Browserversion</p><p>– Betriebssystem des Rechners</p><p>– Referrer URL</p><p>– Hostname des zugreifenden Rechners</p><p>– Uhrzeit der Serveranfrage</p><p>Diese Daten sind nicht personenbezogen. Es erfolgt keine Zusammenführung dieser Daten mit anderen Datenquellen. Wenn uns konkrete Anhaltspunkte für eine rechtswidrige Nutzung bekannt werden behalten wir uns das Recht vor, diese Daten nachträglich zu überprüfen.</p>

  <h3>Kontaktformular</h3>
  <p>
  Kontaktdaten, die mich über das Kontaktformular erreichen, werden inklusive des Inhalts der Anfrage für Bearbeitungszwecke und für mögliche Anschlussfragen gespeichert. Diese Daten werden ohne Ihre spezifische Zustimmung nicht weitergegeben.
  </p>

  <h3>Änderung der Datenschutzbestimmungen</h3>
  <p>
  Diese Datenschutzerklärung kann in unregelmäßigen Abständen angepasst werden, damit sie den aktuellen rechtlichen Anforderungen entsprechen oder um Änderungen in der Webseite umzusetzen. Für Ihren nächsten Besuch gilt dann automatisch die neue Datenschutzerklärung.
  </p>
  <br>
  <p>
  Für Fragen zum Datenschutz schicken Sie eine Nachricht an mikhail@andreev.work mit dem Betreff „Datenschutz“.
  </p>
</div>
