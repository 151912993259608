<div fxLayout='column' [ngStyle]="{'min-height':'100%', position: 'relative'}">
  <ng-template [ngIf]="this.url.indexOf('/admin') == -1">
  <app-header fxFlex="0 0 auto"></app-header>
</ng-template>
   
  <main [@fadeAnimation]="getRouterOutletState(o)" 
    class="bottom-nav-padding"
    [ngClass.lt-md]="{'top-nav-padding': this.url !== '/'}" >
    <router-outlet #o="outlet"></router-outlet>
  </main>
  <app-footer fxShow [fxHide.lt-md]="this.url === '/'" fxFlexAlign="center" fxFlex="0 0 auto"></app-footer>
</div>

